import { lazy, Suspense, useEffect } from 'react'
import useSessionExpiration from './utils/hooks/useSessionExpiration'
import AppFallback from './components/Layouts/AppFallback'
import { notification } from 'antd'
import ToastProvider from './components/Toast/ToastContext'
import { useLocation } from 'react-router-dom'
import ReactGA from "react-ga4"

const AppRoutes = lazy(() => import('./utils/routes/AppRoutes'))
export default function App() {
  useSessionExpiration()
  const [api, toastContextHolder] = notification.useNotification({
    stack: false,
    maxCount: 4,
  })

  const location = useLocation()

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location])

  return (
    <Suspense fallback={<AppFallback />}>
      {toastContextHolder}
      <ToastProvider api={api}>
        <AppRoutes />
      </ToastProvider>
    </Suspense>
  )
}
