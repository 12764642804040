export const DEFAULT_COORDS: [number, number] = [
  Number(process.env.REACT_APP_MAP_DEFAULT_LAT),
  Number(process.env.REACT_APP_MAP_DEFAULT_LON),
]
export const UPLOAD_FILE_SIZE_LIMIT = 10
export const PROFESSIONS_LIMIT = 5
export const INTERESTS_LIMIT = 5
export const SKILLS_LIMIT = 10
export const CERTIFICATES_LIMIT = 10
export const AGE_LIMIT = 14
export const ABOUT_ME_MAX_LENGTH = 250
export const PUBLICATION_MAX_LENGTH = 300
export const PUBLICATION_MEDIA_LIMIT = 10
export const INTERESTS_ZONE_RADIUS = { MIN: 1, MAX: 25 }
export const FEED_CHUNK_SIZE = 10
export const SEARCH_NEIB_CHUNK_SIZE = 10
export const allowedTagsObject = {
  allowedTags: ['b', 'p', 'ol', 'ul', 'li', 'strong', 'em', 's', 'u', 'br', 'a'],
}
