import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export enum LESSONS {
  CHAT_LESSON = 'CHAT_LESSON',
  BUG_REPORT = 'BUG_REPORT',
}
interface State {
  isWelcome: boolean
  watchedLessons: LESSONS[]
}

export const initialState: State = {
  isWelcome: false,
  watchedLessons: [],
}

export const appReducer = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsWelcome: (state, { payload }: PayloadAction<boolean>) => {
      state.isWelcome = payload
    },
    setWatchedLessons: (state, { payload }: PayloadAction<LESSONS>) => {
      if (!state.watchedLessons) {
        state.watchedLessons = []
      }
      state.watchedLessons?.push(payload)
    },
  },
})

export const { setIsWelcome, setWatchedLessons } = appReducer.actions
export default appReducer.reducer
